.treatment-content h1 {
    font-size: 56px;
    color:#222;
    font-weight:700;
    text-align:center;
}
/* .maleproblem-box {
    border: 2px solid #111;
    padding: 1rem
} */
.maleproblem-box span {
    font-size: 30px;
    font-weight: 700;
    padding: 0.25rem 1rem;
    width: 40%;
    color: #ffffff;
    margin: auto;
    text-align: center;
    display: block;
    /* margin-top: -55px; */
    position: relative;
}
.maleproblem-box span.bg-m::before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 45%;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #E31E25;
}
.maleproblem-box span.bg-wi::before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 45%;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #009746;
}
.maleproblem-box span.bg-m::before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 45%;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #E31E25;
}
.maleproblem-box span.bg-sp::before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 45%;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #008DD2;
}
.maleproblem-box span.bg-ar::before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 45%;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #EF7F1B;
}
.maleproblem-box span.bg-f::before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 45%;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #E4097F;
}
.maleproblem-box .card-header {
    /* background: #FC466B;  fallback for old browsers */
    /* background: -webkit-linear-gradient(to right, #3F5EFB, #FC466B);  Chrome 10-25, Safari 5.1-6 */
    /* background: linear-gradient(to right, #3F5EFB, #FC466B); W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: rgb(97, 97, 187);

    border-radius: 2px;
    padding: 0.25rem 1rem;
    border: none;
}
.maleproblem-box p {
    font-size: 18px;
}
.maleproblem-box .card-header h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
}
.maleproblem-box .card-header h2 button, .maleproblem-box .card-header h2 button:hover {
    font-size: 20px;
    color: #fff;
    text-decoration: none;
    font-weight: 700;
    padding: 0;
}
.maleproblem-box .card-header h2 i {
    font-size: 16px;
    cursor: pointer;
}
.maleproblem-box .card-body h4, .maleproblem-box .card-body h5 {
    font-weight: 600
}

@media (max-width: 600px) {
    .maleproblem-box span {
        width: 100%;
        font-size: 28px;
    }
    .maleproblem-box .card-header h2 button, .maleproblem-box .card-header h2 button:hover {
        font-size: 20px;
    }
}
@media (min-width: 320px) and (max-width: 360px) {
    .maleproblem-box .card-header h2 button, .maleproblem-box .card-header h2 button:hover {
        font-size: 20px;
        margin-top: 5px;
    }
}