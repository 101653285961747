.coccoc-alo-phone.coccoc-alo-green .coccoc-alo-ph-img-circle {
    background-color: #00aff2;
    background-color: #ff0000;
}

.coccoc-alo-phone {
    position: fixed;
    visibility: hidden;
    background-color: transparent;
    width: 136px;
    height: 175px;
    cursor: pointer;
    z-index: 200000 !important;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0);
    -webkit-transition: visibility .5s;
    -moz-transition: visibility .5s;
    -o-transition: visibility .5s;
    transition: visibility .5s;
}	



.coccoc-alo-phone.coccoc-alo-show {
	visibility: visible
}

@-webkit-keyframes fadeInRight {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0)
	}
	100% {
		opacity: 1;
		-webkit-transform: none;
		transform: none
	}
}

@-webkit-keyframes fadeInRightBig {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(2000px, 0, 0);
		transform: translate3d(2000px, 0, 0)
	}
	100% {
		opacity: 1;
		-webkit-transform: none;
		transform: none
	}
}

@-webkit-keyframes fadeOutRight {
	0% {
		opacity: 1
	}
	100% {
		opacity: 0;
		-webkit-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0)
	}
}

.fadeOutRight {
	-webkit-animation-name: fadeOutRight;
	animation-name: fadeOutRight
}

.coccoc-alo-phone.coccoc-alo-static {
	opacity: .6
}

.coccoc-alo-phone.coccoc-alo-hover,
.coccoc-alo-phone:hover {
	opacity: 1
}

.coccoc-alo-ph-circle {
	width: 160px;
	height: 160px;
	top: 20px;
	left: 20px;
	position: absolute;
	background-color: transparent;
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	border-radius: 100%;
	border: 2px solid rgba(30, 30, 30, 0.4);
	border: 2px solid #bfebfc 9;
	opacity: .1;
	-webkit-animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
	-moz-animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
	-ms-animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
	-o-animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
	animation: coccoc-alo-circle-anim 1.2s infinite ease-in-out;
	-webkit-transition: all .5s;
	-moz-transition: all .5s;
	-o-transition: all .5s;
	transition: all .5s;
	-webkit-transform-origin: 50% 50%;
	-moz-transform-origin: 50% 50%;
	-ms-transform-origin: 50% 50%;
	-o-transform-origin: 50% 50%;
	transform-origin: 50% 50%
}

.coccoc-alo-phone.coccoc-alo-active .coccoc-alo-ph-circle {
	-webkit-animation: coccoc-alo-circle-anim 1.1s infinite ease-in-out !important;
	-moz-animation: coccoc-alo-circle-anim 1.1s infinite ease-in-out !important;
	-ms-animation: coccoc-alo-circle-anim 1.1s infinite ease-in-out !important;
	-o-animation: coccoc-alo-circle-anim 1.1s infinite ease-in-out !important;
	animation: coccoc-alo-circle-anim 1.1s infinite ease-in-out !important
}

.coccoc-alo-phone.coccoc-alo-static .coccoc-alo-ph-circle {
	-webkit-animation: coccoc-alo-circle-anim 2.2s infinite ease-in-out !important;
	-moz-animation: coccoc-alo-circle-anim 2.2s infinite ease-in-out !important;
	-ms-animation: coccoc-alo-circle-anim 2.2s infinite ease-in-out !important;
	-o-animation: coccoc-alo-circle-anim 2.2s infinite ease-in-out !important;
	animation: coccoc-alo-circle-anim 2.2s infinite ease-in-out !important
}

.coccoc-alo-phone.coccoc-alo-hover .coccoc-alo-ph-circle,
.coccoc-alo-phone:hover .coccoc-alo-ph-circle {
	border-color: #00aff2;
	opacity: .5
}

.coccoc-alo-phone.coccoc-alo-green.coccoc-alo-hover .coccoc-alo-ph-circle,
.coccoc-alo-phone.coccoc-alo-green:hover .coccoc-alo-ph-circle {
	border-color: #75eb50;
	border-color: #baf5a7 9;
	opacity: .5
}

.coccoc-alo-phone.coccoc-alo-green .coccoc-alo-ph-circle {
	border-color: #f00;
	opacity: .5
}

.coccoc-alo-phone.coccoc-alo-gray.coccoc-alo-hover .coccoc-alo-ph-circle,
.coccoc-alo-phone.coccoc-alo-gray:hover .coccoc-alo-ph-circle {
	border-color: #ccc;
	opacity: .5
}

.coccoc-alo-phone.coccoc-alo-gray .coccoc-alo-ph-circle {
	border-color: #75eb50;
	opacity: .5
}

.coccoc-alo-ph-circle-fill {
	width: 100px;
	height: 100px;
	top: 50px;
	left: 50px;
	position: absolute;
	background-color: #000;
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	border-radius: 100%;
	border: 2px solid transparent;
	opacity: .1;
	-webkit-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
	-moz-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
	-ms-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
	-o-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
	animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out;
	-webkit-transition: all .5s;
	-moz-transition: all .5s;
	-o-transition: all .5s;
	transition: all .5s;
	-webkit-transform-origin: 50% 50%;
	-moz-transform-origin: 50% 50%;
	-ms-transform-origin: 50% 50%;
	-o-transform-origin: 50% 50%;
	transform-origin: 50% 50%
}

.coccoc-alo-phone.coccoc-alo-active .coccoc-alo-ph-circle-fill {
	-webkit-animation: coccoc-alo-circle-fill-anim 1.7s infinite ease-in-out !important;
	-moz-animation: coccoc-alo-circle-fill-anim 1.7s infinite ease-in-out !important;
	-ms-animation: coccoc-alo-circle-fill-anim 1.7s infinite ease-in-out !important;
	-o-animation: coccoc-alo-circle-fill-anim 1.7s infinite ease-in-out !important;
	animation: coccoc-alo-circle-fill-anim 1.7s infinite ease-in-out !important
}

.coccoc-alo-phone.coccoc-alo-static .coccoc-alo-ph-circle-fill {
	-webkit-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out !important;
	-moz-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out !important;
	-ms-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out !important;
	-o-animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out !important;
	animation: coccoc-alo-circle-fill-anim 2.3s infinite ease-in-out !important;
	opacity: 0 !important
}

.coccoc-alo-phone.coccoc-alo-hover .coccoc-alo-ph-circle-fill,
.coccoc-alo-phone:hover .coccoc-alo-ph-circle-fill {
	background-color: rgba(0, 175, 242, 0.5);
	background-color: #00aff2 9;
	opacity: .75 !important
}

.coccoc-alo-phone.coccoc-alo-green.coccoc-alo-hover .coccoc-alo-ph-circle-fill,
.coccoc-alo-phone.coccoc-alo-green:hover .coccoc-alo-ph-circle-fill {
	background-color: rgba(117, 235, 80, 0.5);
	background-color: #baf5a7 9;
	opacity: .75 !important
}

.coccoc-alo-phone.coccoc-alo-green .coccoc-alo-ph-circle-fill {
	background-color: #f00;
	background-color: #a6e3fa 9;
	opacity: .75 !important
}

.coccoc-alo-phone.coccoc-alo-gray.coccoc-alo-hover .coccoc-alo-ph-circle-fill,
.coccoc-alo-phone.coccoc-alo-gray:hover .coccoc-alo-ph-circle-fill {
	background-color: rgba(204, 204, 204, 0.5);
	background-color: #ccc 9;
	opacity: .75 !important
}

.coccoc-alo-phone.coccoc-alo-gray .coccoc-alo-ph-circle-fill {
	background-color: rgba(117, 235, 80, 0.5);
	opacity: .75 !important
}

.coccoc-alo-ph-img-circle {
	width: 60px;
	height: 60px;
	top: 70px;
	left: 70px;
	position: absolute;
	background: rgba(30, 30, 30, 0.1) url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABNmlDQ1BQaG90b3Nob3AgSUNDIHByb2ZpbGUAAHjarY6xSsNQFEDPi6LiUCsEcXB4kygotupgxqQtRRCs1SHJ1qShSmkSXl7VfoSjWwcXd7/AyVFwUPwC/0Bx6uAQIYODCJ7p3MPlcsGo2HWnYZRhEGvVbjrS9Xw5+8QMUwDQCbPUbrUOAOIkjvjB5ysC4HnTrjsN/sZ8mCoNTIDtbpSFICpA/0KnGsQYMIN+qkHcAaY6addAPAClXu4vQCnI/Q0oKdfzQXwAZs/1fDDmADPIfQUwdXSpAWpJOlJnvVMtq5ZlSbubBJE8HmU6GmRyPw4TlSaqo6MukP8HwGK+2G46cq1qWXvr/DOu58vc3o8QgFh6LFpBOFTn3yqMnd/n4sZ4GQ5vYXpStN0ruNmAheuirVahvAX34y/Axk/96FpPYgAAACBjSFJNAAB6JQAAgIMAAPn/AACA6AAAUggAARVYAAA6lwAAF2/XWh+QAAAB/ElEQVR42uya7W3CMBCG31QM4A1aNggTlG6QbpBMkHYC1AloJ4BOABuEDcgGtBOETnD9c1ERCH/lwxeaV8oPFGP86Hy+DxMREW5Bd7gRjSDSNGn4/RiAOvm8C0ZCRD5PSkQVXSr1nK/xE3mcWimA1ZV3JYBZCIO4giQANoYxMwYS6+xKY4lT5dJPreWZY+uspqSCKPYN27GJVBDXheVSQe494ksiEWTuMXcu1dld9SARxDX1OAJ4lgjy4zDnFsC076A4adEiRwAZg4hOUSpNoCsBPDGM+HqkNGynYBCuILuWj+dgWysGsNe8nwL4GsrW0m2fxZBq9rW0rNcX5MOQ9eZD8JFahcG5g/iKT671alGAYQggpYWvpEPYWrU/HDTOfeRIX0q2SL3QN4tGhZJukVobQyXYWw7WtLDKDIuM+ZSzscyCE9PCy5IttCvnZNaeiGLNHKuz8ZVh/MXTVu/1xQKmIqLEAuJ0fNo3iG5B51oSkeKnsBi/4bG9gYB/lCytU5G9DryFW+3Gm+JLwU7ehbJrwTjq4DJU8bHcVbEV9dXXqqP6uqO5e2/QZRYJpqu2IUAA4B3tXvx8hgKp05QZW6dJqrLTNkB6vrRURLRwPHqtYgkC3cLWQAcDQGGKH13FER/NATzi786+BPDNjm1dMkfjn2pGkBHkf4D8DgBJDuDHx9BN+gAAAABJRU5ErkJggg==") no-repeat center center;
	-webkit-border-radius: 100%;
	-moz-border-radius: 100%;
	border-radius: 100%;
	border: 2px solid transparent;
	opacity: .7;
	-webkit-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
	-moz-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
	-ms-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
	-o-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
	animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out;
	-webkit-transform-origin: 50% 50%;
	-moz-transform-origin: 50% 50%;
	-ms-transform-origin: 50% 50%;
	-o-transform-origin: 50% 50%;
    transform-origin: 50% 50%;   
}
.bgred {
    background-color: red !important; 
}
.bgred:hover {
    background-color: #75eb50 !important;
}
.coccoc-alo-phone.coccoc-alo-active .coccoc-alo-ph-img-circle {
	-webkit-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out !important;
	-moz-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out !important;
	-ms-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out !important;
	-o-animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out !important;
	animation: coccoc-alo-circle-img-anim 1s infinite ease-in-out !important
}

.coccoc-alo-phone.coccoc-alo-static .coccoc-alo-ph-img-circle {
	-webkit-animation: coccoc-alo-circle-img-anim 0s infinite ease-in-out !important;
	-moz-animation: coccoc-alo-circle-img-anim 0s infinite ease-in-out !important;
	-ms-animation: coccoc-alo-circle-img-anim 0s infinite ease-in-out !important;
	-o-animation: coccoc-alo-circle-img-anim 0s infinite ease-in-out !important;
	animation: coccoc-alo-circle-img-anim 0s infinite ease-in-out !important
}

.coccoc-alo-phone.coccoc-alo-hover .coccoc-alo-ph-img-circle,
.coccoc-alo-phone:hover .coccoc-alo-ph-img-circle {
	background-color: #00aff2
}

.coccoc-alo-phone.coccoc-alo-green.coccoc-alo-hover .coccoc-alo-ph-img-circle,
.coccoc-alo-phone.coccoc-alo-green:hover .coccoc-alo-ph-img-circle {
	background-color: #75eb50;
	background-color: #75eb50
}

.coccoc-alo-phone.coccoc-alo-green .coccoc-alo-ph-img-circle {
	background-color: #00aff2;
	background-color: #75eb50
}

.coccoc-alo-phone.coccoc-alo-gray.coccoc-alo-hover .coccoc-alo-ph-img-circle,
.coccoc-alo-phone.coccoc-alo-gray:hover .coccoc-alo-ph-img-circle {
	background-color: #ccc
}

.coccoc-alo-phone.coccoc-alo-gray .coccoc-alo-ph-img-circle {
	background-color: #75eb50
}

@-moz-keyframes coccoc-alo-circle-anim {
	0% {
		-moz-transform: rotate(0) scale(.5) skew(1deg);
		opacity: .1;
		-moz-opacity: .1;
		-webkit-opacity: .1;
		-o-opacity: .1
	}
	30% {
		-moz-transform: rotate(0) scale(.7) skew(1deg);
		opacity: .5;
		-moz-opacity: .5;
		-webkit-opacity: .5;
		-o-opacity: .5
	}
	100% {
		-moz-transform: rotate(0) scale(1) skew(1deg);
		opacity: .6;
		-moz-opacity: .6;
		-webkit-opacity: .6;
		-o-opacity: .1
	}
}

@-webkit-keyframes coccoc-alo-circle-anim {
	0% {
		-webkit-transform: rotate(0) scale(.5) skew(1deg);
		-webkit-opacity: .1
	}
	30% {
		-webkit-transform: rotate(0) scale(.7) skew(1deg);
		-webkit-opacity: .5
	}
	100% {
		-webkit-transform: rotate(0) scale(1) skew(1deg);
		-webkit-opacity: .1
	}
}

@-o-keyframes coccoc-alo-circle-anim {
	0% {
		-o-transform: rotate(0) kscale(.5) skew(1deg);
		-o-opacity: .1
	}
	30% {
		-o-transform: rotate(0) scale(.7) skew(1deg);
		-o-opacity: .5
	}
	100% {
		-o-transform: rotate(0) scale(1) skew(1deg);
		-o-opacity: .1
	}
}

@-moz-keyframes coccoc-alo-circle-fill-anim {
	0% {
		-moz-transform: rotate(0) scale(.7) skew(1deg);
		opacity: .2
	}
	50% {
		-moz-transform: rotate(0) -moz-scale(1) skew(1deg);
		opacity: .2
	}
	100% {
		-moz-transform: rotate(0) scale(.7) skew(1deg);
		opacity: .2
	}
}

@-webkit-keyframes coccoc-alo-circle-fill-anim {
	0% {
		-webkit-transform: rotate(0) scale(.7) skew(1deg);
		opacity: .2
	}
	50% {
		-webkit-transform: rotate(0) scale(1) skew(1deg);
		opacity: .2
	}
	100% {
		-webkit-transform: rotate(0) scale(.7) skew(1deg);
		opacity: .2
	}
}

@-o-keyframes coccoc-alo-circle-fill-anim {
	0% {
		-o-transform: rotate(0) scale(.7) skew(1deg);
		opacity: .2
	}
	50% {
		-o-transform: rotate(0) scale(1) skew(1deg);
		opacity: .2
	}
	100% {
		-o-transform: rotate(0) scale(.7) skew(1deg);
		opacity: .2
	}
}

@-moz-keyframes coccoc-alo-circle-img-anim {
	0% {
		transform: rotate(0) scale(1) skew(1deg)
	}
	10% {
		-moz-transform: rotate(-25deg) scale(1) skew(1deg)
	}
	20% {
		-moz-transform: rotate(25deg) scale(1) skew(1deg)
	}
	30% {
		-moz-transform: rotate(-25deg) scale(1) skew(1deg)
	}
	40% {
		-moz-transform: rotate(25deg) scale(1) skew(1deg)
	}
	50% {
		-moz-transform: rotate(0) scale(1) skew(1deg)
	}
	100% {
		-moz-transform: rotate(0) scale(1) skew(1deg)
	}
}

@-webkit-keyframes coccoc-alo-circle-img-anim {
	0% {
		-webkit-transform: rotate(0) scale(1) skew(1deg)
	}
	10% {
		-webkit-transform: rotate(-25deg) scale(1) skew(1deg)
	}
	20% {
		-webkit-transform: rotate(25deg) scale(1) skew(1deg)
	}
	30% {
		-webkit-transform: rotate(-25deg) scale(1) skew(1deg)
	}
	40% {
		-webkit-transform: rotate(25deg) scale(1) skew(1deg)
	}
	50% {
		-webkit-transform: rotate(0) scale(1) skew(1deg)
	}
	100% {
		-webkit-transform: rotate(0) scale(1) skew(1deg)
	}
}

@-o-keyframes coccoc-alo-circle-img-anim {
	0% {
		-o-transform: rotate(0) scale(1) skew(1deg)
	}
	10% {
		-o-transform: rotate(-25deg) scale(1) skew(1deg)
	}
	20% {
		-o-transform: rotate(25deg) scale(1) skew(1deg)
	}
	30% {
		-o-transform: rotate(-25deg) scale(1) skew(1deg)
	}
	40% {
		-o-transform: rotate(25deg) scale(1) skew(1deg)
	}
	50% {
		-o-transform: rotate(0) scale(1) skew(1deg)
	}
	100% {
		-o-transform: rotate(0) scale(1) skew(1deg)
	}
}

@-moz-keyframes fadeInRight {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(100%, 0, 0);
		-ms-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0)
	}
	100% {
		opacity: 1;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none
	}
}

@-webkit-keyframes fadeInRight {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(100%, 0, 0);
		-ms-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0)
	}
	100% {
		opacity: 1;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none
	}
}

@-o-keyframes fadeInRight {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(100%, 0, 0);
		-ms-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0)
	}
	100% {
		opacity: 1;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none
	}
}

@keyframes fadeInRight {
	0% {
		opacity: 0;
		-webkit-transform: translate3d(100%, 0, 0);
		-ms-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0)
	}
	100% {
		opacity: 1;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none
	}
}

@-moz-keyframes fadeOutRight {
	0% {
		opacity: 1
	}
	100% {
		opacity: 0;
		-webkit-transform: translate3d(100%, 0, 0);
		-ms-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0)
	}
}

@-webkit-keyframes fadeOutRight {
	0% {
		opacity: 1
	}
	100% {
		opacity: 0;
		-webkit-transform: translate3d(100%, 0, 0);
		-ms-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0)
	}
}

@-o-keyframes fadeOutRight {
	0% {
		opacity: 1
	}
	100% {
		opacity: 0;
		-webkit-transform: translate3d(100%, 0, 0);
		-ms-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0)
	}
}

@keyframes fadeOutRight {
	0% {
		opacity: 1
	}
	100% {
		opacity: 0;
		-webkit-transform: translate3d(100%, 0, 0);
		-ms-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0)
	}
}

@-moz-keyframes coccoc-alo-circle-anim {
	0% {
		transform: rotate(0) scale(.5) skew(1deg);
		opacity: .1
	}
	30% {
		transform: rotate(0) scale(.7) skew(1deg);
		opacity: .5
	}
	100% {
		transform: rotate(0) scale(1) skew(1deg);
		opacity: .1
	}
}

@-webkit-keyframes coccoc-alo-circle-anim {
	0% {
		transform: rotate(0) scale(.5) skew(1deg);
		opacity: .1
	}
	30% {
		transform: rotate(0) scale(.7) skew(1deg);
		opacity: .5
	}
	100% {
		transform: rotate(0) scale(1) skew(1deg);
		opacity: .1
	}
}

@-o-keyframes coccoc-alo-circle-anim {
	0% {
		transform: rotate(0) scale(.5) skew(1deg);
		opacity: .1
	}
	30% {
		transform: rotate(0) scale(.7) skew(1deg);
		opacity: .5
	}
	100% {
		transform: rotate(0) scale(1) skew(1deg);
		opacity: .1
	}
}

@keyframes coccoc-alo-circle-anim {
	0% {
		transform: rotate(0) scale(.5) skew(1deg);
		opacity: .1
	}
	30% {
		transform: rotate(0) scale(.7) skew(1deg);
		opacity: .5
	}
	100% {
		transform: rotate(0) scale(1) skew(1deg);
		opacity: .1
	}
}

@-moz-keyframes coccoc-alo-circle-fill-anim {
	0% {
		transform: rotate(0) scale(.7) skew(1deg);
		opacity: .2
	}
	50% {
		transform: rotate(0) scale(1) skew(1deg);
		opacity: .2
	}
	100% {
		transform: rotate(0) scale(.7) skew(1deg);
		opacity: .2
	}
}

@-webkit-keyframes coccoc-alo-circle-fill-anim {
	0% {
		transform: rotate(0) scale(.7) skew(1deg);
		opacity: .2
	}
	50% {
		transform: rotate(0) scale(1) skew(1deg);
		opacity: .2
	}
	100% {
		transform: rotate(0) scale(.7) skew(1deg);
		opacity: .2
	}
}

@-o-keyframes coccoc-alo-circle-fill-anim {
	0% {
		transform: rotate(0) scale(.7) skew(1deg);
		opacity: .2
	}
	50% {
		transform: rotate(0) scale(1) skew(1deg);
		opacity: .2
	}
	100% {
		transform: rotate(0) scale(.7) skew(1deg);
		opacity: .2
	}
}

@keyframes coccoc-alo-circle-fill-anim {
	0% {
		transform: rotate(0) scale(.7) skew(1deg);
		opacity: .2
	}
	50% {
		transform: rotate(0) scale(1) skew(1deg);
		opacity: .2
	}
	100% {
		transform: rotate(0) scale(.7) skew(1deg);
		opacity: .2
	}
}

@-moz-keyframes coccoc-alo-circle-img-anim {
	0% {
		transform: rotate(0) scale(1) skew(1deg)
	}
	10% {
		transform: rotate(-25deg) scale(1) skew(1deg)
	}
	20% {
		transform: rotate(25deg) scale(1) skew(1deg)
	}
	30% {
		transform: rotate(-25deg) scale(1) skew(1deg)
	}
	40% {
		transform: rotate(25deg) scale(1) skew(1deg)
	}
	50% {
		transform: rotate(0) scale(1) skew(1deg)
	}
	100% {
		transform: rotate(0) scale(1) skew(1deg)
	}
}

@-webkit-keyframes coccoc-alo-circle-img-anim {
	0% {
		transform: rotate(0) scale(1) skew(1deg)
	}
	10% {
		transform: rotate(-25deg) scale(1) skew(1deg)
	}
	20% {
		transform: rotate(25deg) scale(1) skew(1deg)
	}
	30% {
		transform: rotate(-25deg) scale(1) skew(1deg)
	}
	40% {
		transform: rotate(25deg) scale(1) skew(1deg)
	}
	50% {
		transform: rotate(0) scale(1) skew(1deg)
	}
	100% {
		transform: rotate(0) scale(1) skew(1deg)
	}
}

@-o-keyframes coccoc-alo-circle-img-anim {
	0% {
		transform: rotate(0) scale(1) skew(1deg)
	}
	10% {
		transform: rotate(-25deg) scale(1) skew(1deg)
	}
	20% {
		transform: rotate(25deg) scale(1) skew(1deg)
	}
	30% {
		transform: rotate(-25deg) scale(1) skew(1deg)
	}
	40% {
		transform: rotate(25deg) scale(1) skew(1deg)
	}
	50% {
		transform: rotate(0) scale(1) skew(1deg)
	}
	100% {
		transform: rotate(0) scale(1) skew(1deg)
	}
}

@keyframes coccoc-alo-circle-img-anim {
	0% {
		transform: rotate(0) scale(1) skew(1deg)
	}
	10% {
		transform: rotate(-25deg) scale(1) skew(1deg)
	}
	20% {
		transform: rotate(25deg) scale(1) skew(1deg)
	}
	30% {
		transform: rotate(-25deg) scale(1) skew(1deg)
	}
	40% {
		transform: rotate(25deg) scale(1) skew(1deg)
	}
	50% {
		transform: rotate(0) scale(1) skew(1deg)
	}
	100% {
		transform: rotate(0) scale(1) skew(1deg)
	}
}

#coccoc-alo-wrapper {
	position: fixed;
	width: 100%;
	bottom: 0;
	top: 0;
	left: 0;
	z-index: 2000000;
	overflow: visible;
	display: none;
	color: #383838
}

#coccoc-alo-wrapper.night-mode {
	color: #fff
}

.coccoc-alo-popup-close {
	-webkit-border-radius: 2px !important;
	-moz-border-radius: 2px !important;
	-webkit-border-radius: 2px;
	-moz-border-radius: 2px;
	border-radius: 2px;
	position: absolute !important;
	right: -15px !important;
	top: -15px !important;
	height: 30px !important;
	width: 30px !important;
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3FpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDozZWEyNDI5ZC0yYmI3LWYzNDMtYjBjZi1jMGJjYTE4ODRmZjkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NjRGMTI2QTcxNDBFMTFFNUFENEZCRDVFQ0JDQjQyQzIiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NjRGMTI2QTYxNDBFMTFFNUFENEZCRDVFQ0JDQjQyQzIiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjVmYzc3OTY1LWUxNWUtNGU0Ni04ODFjLTBlOTQ3YjBmMzBmNyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDozZWEyNDI5ZC0yYmI3LWYzNDMtYjBjZi1jMGJjYTE4ODRmZjkiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5iCEbHAAABl0lEQVR42sSXS07DMBCGnSKyDorEAVjACTgCIEVlXU5R9QjlCk3VAzTrLhMJ2NIVJ2DDuo9EsKUszEw0kaIQbI+bxy/9UhRP5pMcjz12pJTCQKfgO/AN+Bp8AfZo7Av8AX4Dv4CfwD/ajAhW2ANPwTtprj1946lyq6AP4I2014ZyGINPwAvZnBaUUwnGgJVsXqsqvAoOZXua/wceyfY1KngOlROWxjv4XLSrHfgKS3BALyYdQAUxJkUdu7o6jeNYZlmmnUeMwViNkOUieKiLTNNURlGkhOPYcrnMYw00RPDMJFIFZ0JRIYJfTaPr4BZQ1Fow9+EcgCAEWkLz/4zl9A1rzOUsTQCKJEny5yAIhO/73NV9GNjUhOM4tc8scae6PL3laedONYLXNtC6f85dXDNb6BHw0GgDKaCqxEz4fbFlpk1smQjnbJmCeqSuNO3jWNyDL8vHIrao4w6OxTGx/rQ+8z5an16bvd7a22pDvz0CuOU29NUrzKOuzqvlTN8orzAO89J2W7q0ndHYZ+nS9kw+6BL+CjAAEvDTBJC9qhAAAAAASUVORK5CYII=");
	background-position: center center;
	background-repeat: no-repeat;
	cursor: pointer !important;
	-webkit-transition: .3s ease-out !important;
	-moz-transition: .3s ease-out !important;
	-o-transition: .3s ease-out !important;
	transition: .3s ease-out !important
}

#coccoc-alo-wrapper.night-mode .coccoc-alo-popup-close {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3FpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDozZWEyNDI5ZC0yYmI3LWYzNDMtYjBjZi1jMGJjYTE4ODRmZjkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OUY2REUyNDQxNDE2MTFFNThBNEJENTVFNDA2QjFFOUEiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OUY2REUyNDMxNDE2MTFFNThBNEJENTVFNDA2QjFFOUEiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjVmYzc3OTY1LWUxNWUtNGU0Ni04ODFjLTBlOTQ3YjBmMzBmNyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDozZWEyNDI5ZC0yYmI3LWYzNDMtYjBjZi1jMGJjYTE4ODRmZjkiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz56uyuzAAABfUlEQVR42sSXvU7DMBDHYxCdw8IDMMCWTDwCdClznLcJr9BUfYs+ALDSqXMisTD3S4K1MBx3kS1ZVuqvNslf+kuRfL5f5OTsMwOAyEFX6DH6Ef2AvkXHYuwH/YVeod/Rr+g/a0YCGxyjC/QW3LUTc2JTbhOUo9cQrrXI4Qy+RM/hfJqLnEYwBSzg/FrocB1cQneaHQNn0L0yyWOinKg0PtE3Ubfaou+bEhRvUEB/KuRSj2x1muc51HVtzUgxnHNbGLFGBJ7YIquqgjRNjXAaS5KkiXXQhMBTl0gT3BNKKgn84RrdBg+AkpaR5z7cAAhEwEBo850JfPCdJeGBUNLhIqQYGWOtz17yXWp1edVlD1nqZQi07Zv7/lzTUOgJ8NJpA5FQU2JP+LPcMvfGIyXLnBISnGJdt8xBDom+j8Ud+k49FvtqBPix1mc2ROszaLM3WHurN/SbE4Ab34Zev8K82Opc017MMV5hmOel7Um5tF2LsW/l0vYm/GtL+C/AAAHy+OD95QLeAAAAAElFTkSuQmCC")
}

#coccoc-alo-wrapper .coccoc-alo-popup-close:hover {
	opacity: .6 !important
}

.coccoc-alo-popup {
	display: inline-block;
	position: relative;
	-webkit-border-radius: 16px;
	-moz-border-radius: 16px;
	border-radius: 16px;
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH3wYZCyIUPNCUUwAAAA1JREFUCNdj+P///2cACe8D8SyfS4EAAAAASUVORK5CYII=");
	-webkit-transition: .6s ease-out;
	-moz-transition: .6s ease-out;
	-o-transition: .6s ease-out;
	transition: .6s ease-out;
	margin: 0 auto;
	z-index: 200001;
	text-align: center;
	padding: 60px 75px
}

#coccoc-alo-wrapper.night-mode .coccoc-alo-popup {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH3wYZCyAMHYpuhwAAAA1JREFUCNdjMDY2/gwAAsMBjX/tf+YAAAAASUVORK5CYII=")
}

#coccoc-alo-wrapper .coccoc-alo-overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUAAACnej3aAAAAAXRSTlOZyTXzhgAAAApJREFUCB1jYAAAAAIAAc/INeUAAAAASUVORK5CYII=");
	top: 0;
	left: 0;
	z-index: 200000
}

.coccoc-alo-popup h3 {
	font-size: 24px;
	margin: 0 0 40px;
	font-family: "Open Sans";
	font-weight: 300;
	white-space: nowrap
}

.night-mode .coccoc-alo-popup h3 {
	font-size: 23px
}

.night-mode .coccoc-alo-message {
	padding-bottom: 0
}

.coccoc-alo-message {
	height: 32px;
	padding: 18px 0 13px 0;
	text-align: center;
	clear: both;
	font-size: 14px
}

#coccoc-alo-wrapper .coccoc-alo-popup .coccoc-alo-input-wrapper .label,
#coccoc-alo-wrapper .coccoc-alo-popup .coccoc-alo-input-wrapper .label+.input {
	float: left;
	width: 49%
}

#coccoc-alo-wrapper .coccoc-alo-popup .coccoc-alo-input-wrapper .input {
	padding: 0 13px
}

#coccoc-alo-wrapper .coccoc-alo-popup .coccoc-alo-input-wrapper label {
	color: #616161;
	font-size: 18px;
	height: 28px;
	line-height: 28px;
	padding-right: 15px
}

.coccoc-alo-number {
	color: #00bed5;
	font-size: 28px;
	font-family: Montserrat, "Lucida Console", Monaco, monospace, sans-serif;
	font-weight: normal;
	background-color: transparent;
	border: none;
	border-width: 0;
	display: inline-block;
	border-bottom: #00bed5 solid 1px;
	padding-bottom: 10px;
	margin: 0 auto;
	width: 221px
}

coccoc-alo-number::-ms-clear {
	display: none;
	width: 0;
	height: 0
}

.night-mode .coccoc-alo-number {
	border: #00bed5 solid 1px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	padding: 13px 31px
}

#coccoc-alo-wrapper .coccoc-alo-popup .coccoc-alo-input-wrapper input[type="text"].valid-invalid {
	color: #ff496b
}

#coccoc-alo-wrapper .coccoc-alo-popup .coccoc-alo-input-wrapper input[type="text"]:focus {
	outline: 0
}

#coccoc-alo-wrapper .coccoc-alo-popup .coccoc-alo-input-wrapper input[type="text"]::-webkit-input-placeholder {
	color: #d1d1d1
}

#coccoc-alo-wrapper .coccoc-alo-popup .coccoc-alo-input-wrapper input[type="text"]::-moz-placeholder {
	color: #d1d1d1
}

#coccoc-alo-wrapper .coccoc-alo-popup .coccoc-alo-input-wrapper input[type="text"]::-ms-input-placeholder {
	color: #d1d1d1
}

#coccoc-alo-wrapper .coccoc-alo-popup .coccoc-alo-input-wrapper input[type="text"]::-moz-placeholder {
	color: #d1d1d1
}

#coccoc-alo-wrapper.night-mode .coccoc-alo-popup .coccoc-alo-input-wrapper input[type="text"]::-webkit-input-placeholder {
	color: #60615f
}

#coccoc-alo-wrapper.night-mode .coccoc-alo-popup .coccoc-alo-input-wrapper input[type="text"]::-moz-placeholder {
	color: #60615f
}

#coccoc-alo-wrapper.night-mode .coccoc-alo-popup .coccoc-alo-input-wrapper input[type="text"]::-ms-input-placeholder {
	color: #60615f
}

#coccoc-alo-wrapper.night-mode .coccoc-alo-popup .coccoc-alo-input-wrapper input[type="text"]::-moz-placeholder {
	color: #60615f
}

#coccoc-alo-wrapper .coccoc-alo-popup .coccoc-alo-submit {
	border: none;
	border-width: 0;
	padding: 20px 40px;
	background-color: #333;
	-webkit-border-radius: 68px;
	-moz-border-radius: 68px;
	-webkit-border-radius: 68px;
	-moz-border-radius: 68px;
	border-radius: 68px;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	font-size: 20px;
	color: #fff;
	cursor: pointer;
	outline: none !important
}

#coccoc-alo-wrapper.night-mode .coccoc-alo-popup .coccoc-alo-submit {
	background-color: #00bed5
}

#coccoc-alo-wrapper .coccoc-alo-popup .coccoc-alo-submitavtive,
#coccoc-alo-wrapper .coccoc-alo-popup .coccoc-alo-submitvisited {
	outline: none !important
}

#coccoc-alo-wrapper .coccoc-alo-popup .coccoc-alo-submit-moz-focus-inner {
	border: 0
}

#coccoc-alo-wrapper .coccoc-alo-popup .coccoc-alo-submit:hover {
	background-color: #00aff2;
	background-color: #00aff2
}

.coccoc-alo-blur {
	-webkit-filter: blur(3px);
	-ms-filter: blur(3px);
	-moz-filter: blur(3px);
	-o-filter: blur(3px);
	filter: blur(3px);
	filter: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxmaWx0ZXIgaWQ9ImJsdXIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjMiLz48L2ZpbHRlcj48L3N2Zz4jYmx1cg==#blur")
}

#coccoc-countdown {
	padding-top: 20px;
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	font-size: 28px;
	font-weight: 300
}

.coccoc-alo-request-time {
	font-family: "Open Sans", Arial, Helvetica, sans-serif;
	padding: 6px 12px;
	font-size: 18px;
	background-color: #fff;
	background-image: none;
	border: 1px solid #ccc;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	-webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
	-o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
	transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s
}

#coccoc-alo-wrapper.night-mode .coccoc-alo-request-time {
	color: #fff;
	background-color: #515350;
	border: 1px solid #606260
}

#coccoc-alo-wrapper.night-mode .coccoc-alo-popup h3 {
	margin-bottom: 15px
}

#coccoc-alo-wrapper.night-mode .coccoc-alo-form .coccoc-alo-select-wrapper {
	margin-bottom: 35px
}

.coccoc-alo-table {
	display: table;
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0
}

.coccoc-alo-cell {
	display: table-cell;
	vertical-align: middle;
	text-align: center
}

.valid-invalid-message {
	font-size: 13px;
	color: #ff496b
}

.valid-invalid-message:before {
	content: "* "
}

