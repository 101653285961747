@font-face {
  font-family: 'anomlippi';
  src: url('/font/ANMOL_.TTF') format('truetype'), /* Safari, Android, iOS */
}


@font-face {
  font-family: 'Gabriela';
  src: url('/Gabriela-Alt-ExtraBold.otf'); /* IE9 Compat Modes */
  src: url('/Gabriela-Alt-ExtraBold.otf') format('embedded-opentype'), /* IE6-IE8 */
}

body {
  font-family: 'Tajawal', sans-serif;
  position: relative;
  overflow-x: hidden;
}
* {
  box-sizing: border-box;
}
header .navbar .navbar-toggler {
  border-color: #222;
}
button, button:hover, a, a:hover {
  cursor: pointer;
}
.main-site {
  overflow-x: hidden;
}
.banner-section .carousel-inner .carousel-item img {
  height: 550px;
  object-fit: cover;
  object-position: top center;
  /* -webkit-animation: zoom 20s;
  animation: zoom 20s; */
}

.navbarmenu ul li a {
  color: #222 !important;
  padding: 1rem 1.25rem !important;
  /* transition: all 0.25s ease-out 0s; */
}

.navbarmenu ul li a:hover {
  padding-bottom: 5px;
  background-color: #88b44e;
  color: #fff !important;
  /* transform: scale(1.1); */
}

.navbarmenu ul li a.active {
  background-color: #88b44e;
  color: #fff !important;
}

.sitebrand {
  padding: 0;
}

.navbardark {
  padding: 0 1rem;
  background-color: #ffffff;
  width: 100%;
  overflow: hidden;
}

.navbardark img {
  padding: 0.60rem 0;
}
.bggreen {
  background-color: #88b44e;
  color: #ffffff;
  width: 100%;
}

/* Social Icon */
.socialicon ul li {
  padding: 0 1rem;
}
.socialicon ul li a.fb {
  color: #3b5998;
}
.socialicon ul li a.yt {
  color: #c4302b;
}

.socialicon ul li a i {
  font-size: 24px;
}
.socialicon ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style-type: none;
}
/* Social icon */
/* Language Button */

.languagebtn {
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.languagebtn ul {
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  margin: 0;
}

.languagebtn ul li .btnlang, .languagebtn ul li .btnlang:hover, .languagebtn ul li .btnlang:focus, .languagebtn ul li .btnlang:active {
  background-color: #222;
  color: #fff;
  margin-left: 5px;
  padding: 0.25rem 1rem;
  border: none;
  box-shadow: none;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.m-view .btnlang, .m-view .btnlang:hover, .m-view .btnlang:focus, .m-view .btnlang:active {
  background-color: #222;
  color: #fff;
  padding: 0.25rem 1rem;
  border: none;
  box-shadow: none;
  border-radius: 2px;
  width: 100%;
}
.languagebtn ul li .btn-primary, .languagebtn ul li .btn-primary:hover, .languagebtn ul li .btn-primary:focus, .languagebtn ul li .btn-primary:active {
  background-color: #88b44e;
  color: #fff;
  margin-left: 5px;
  padding: 0.25rem 1rem;
  border: none;
  box-shadow: none;
  border-radius: 2px;
}
.m-view .btn-primary:hover, .m-view .btn-primary:active, .m-view .btn-primary:focus, .m-view .btn-primary {
  background-color: #88b44e;
  color: #fff;
  padding: 0.25rem 1rem;
  border: none;
  box-shadow: none;
  border-radius: 2px;
  width: 100%;
} 
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
  background-color: #88b44e; 
}
.site-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9999999;
  overflow: hidden;
}

#siteContent {
  margin-top: 56px;
}



/* Language Button */

@keyframes zoom {
  from {
    transform: scale(1, 1);
  }
  to {
    transform: scale(1.5, 1.5);
  }
}
.storysection .postedtime {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 1rem;
}

.dailypost h1 {
  font-size: 20px;
  color: #222;
  font-weight: 700;
}

.dailypost p {
  line-height: 32px;
}

.icons ul {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  padding: 0;
}

.ourservices h4, .aboutsection h4, .socio-icon h4 {
  font-size: 32px;
  font-weight: 700;
  text-align: left;
  color: #222;
  position: relative;
  padding-bottom: 0.5rem;
  font-family: 'Gabriela';
}

.treatmentoffer h4 {
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  color: #222;
  position: relative;
  padding-bottom: 0.5rem;
  font-family: 'Anton', sans-serif;
}

.trustwebuild h4 {
  font-size: 32px;
  font-weight: 500;
  text-align: center;
  color: #fff;
  position: relative;
  padding-bottom: 0.5rem;
  font-family: 'Anton', sans-serif;
}

.treatmentoffer h5 {
  font-size: 14px;
  color: #555;
}

.ourservices, .storysection, .aboutsection, .contact-section, .treatmentoffer, .trustwebuild {
  margin-top: 5rem;
}

.ourservices h4::before {
  content: '';
  position: absolute;
  width: 60px;
  height: 3px;
  background-color: #f1f1f1;
  bottom: 0;
}

.btnknowmore {
  border-radius: 0;
  background-color: #88b44e;
  color: #fff;
  text-transform: uppercase;
  font-family: 'Tajawal', sans-serif;
  padding: 0.5rem 2rem;
}

.service-box {
  /* border: 1px solid #ddd; */
  width: 100%;
  height: 140px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

}

.service-box img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 140px;
  border-radius: 3px;
  transition: all .3s ease-in-out;
}
.service-box img:hover {
  transform: scale(1.1);
}
/* .service-box:hover {
  background-color: #88b44e;
  border-color: #88b44e;
  transition: all .2s linear;
} */
/* 
.service-box:hover img {
  filter: invert(0);
} */

.ourservices p {
  text-align: center;
  margin-top: 1rem;
  font-size: 16px;
}


.aboutsection h4 {
  text-align: left;
}

.contact-section form .btnsubmit {
  background-color: #88b44e;
  color: #fff;
  border-radius: 3px;
  padding: 0.5rem 2rem;
}

.socio-icon ul {
  display: flex;
  justify-content: center;
  list-style-type: none;
  padding: 0;
}

.socio-icon ul li {
  background-color: #fff;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px
}

.socio-icon ul li:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  background-color: #88b44e;
}

.socio-icon ul li:hover i {
  color: #fff;
}

.socio-icon ul li a, .socio-icon ul li a i {
  color: #333;
  font-size: 16px;
}

.btnviewmore, .btnmore, .btnviewmore:hover, .btnmore:hover {
  background-color: #88b44e;
  color: #fff;
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 20px;
  right: 40px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.my-float {
  margin-top: 16px;
}

.callinfo a:hover {
  color: #ffffff;
}

.iconright {
  right:2%;
  bottom: 0;
}
.floatright {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 20px;
  left: 10px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  /* box-shadow: 2px 2px 3px #999; */
  z-index: 100;
}
.btntreatmentweoffer, .btntreatmentweoffer:hover {
  border: none;
  border-radius: 0;
  background-color: #ff0000;
  color: #f1f1f1;
  padding: 0.5rem 1rem;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
}
.btnclickhere {
  background-color: #FFF;
  margin-left: 1.5rem;
  border-radius: 20px;
  padding: 1px 10px;
  color: #000;
  position: relative;
}
.btnclickhere::before {
  content: '';
  position: absolute;
  top: 22px;
  left: 35px;
  background-image: url(/click.svg);
  width: 30px;
  height: 30px;
  z-index: 999999;
  background-position: center;
  background-size: cover;
}
.treatmentoffered a {
  margin-bottom: 1rem;
}
.treatmentoffered .tile {
  width: 100%;
  height: 140px;
  border: 1px solid #ddd;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in;
}
.treatmentoffer .tile img {
  border-radius: 5px;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1);
  transition: all .3s ease-in-out;
}
/* .treatmentoffer .tile img:hover {
  
} */
.treatmentBox {
  background-size: cover;
  background-position: center;
}
.treatmentoffered .tile img {
  width: 50px;
  filter: invert(0);
}
.treatmentoffered .tile:hover {
  background-color: rgba(0, 0, 0, 0.4);
}
.treatmentoffered .tile:hover img {
  filter: invert(1);
}
.treatment-box p {
  background-color: #88b44e;
  color: #ffffff;
  text-align: center;
  padding: 6px;
  font-size: 14px;
  text-transform: uppercase;
}
.treatment-box a {
  text-decoration: none;
}
.trustwebuild {
  width: 100%;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  min-height: 500px;
  display: block;
  padding: 5rem 0;
}

.testimonialbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.testimonialbox .testimonial img {
  border-radius: 100%;
  width: 100px;
}

.testimonialbox .testimonial .rating ul {
  list-style-type: none;
  display: flex;
  padding: 0;
  margin-top: 1rem;
}

.testimonialbox .testimonial .rating ul li {
  margin-right: 10px;
}

.testimonialbox .testimonial .rating ul li i {
  color: orange;
}

.testimonial p {
  color: #fff;
  font-size: 16px;
  min-height: 125px;
}

.testimonial span {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
}

footer {
  background-color: #333;
}

footer {
  color: #fff;
}

footer h5 {
  font-weight: 600;
}

footer ul {
  padding: 0;
}

footer ul li {
  list-style: none;
  line-height: 32px;
}

footer ul li a, footer ul li a:hover {
  color: #ffffff;
  text-decoration: none;
}

footer p a, footer p a:hover {
  padding: 0.5rem 1rem;
  background-color: #88b44e;
  color: #fff;
  border-radius: 3px;
  font-size: 16px;
  text-decoration: none;
}

.floatleft {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 20px;
  left: 40px;
  background-color: #ff0000;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  /* box-shadow: 2px 2px 3px #999; */
  z-index: 100;
}
/* .floatleft i {
  animation: shake 1s ease-in-out infinite;
}
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
} */

@media (max-width: 600px) {
  .icons ul {
    list-style-type: none;
    display: block;
    padding: 0;
  }
  .socio-icon ul li {
    margin: 0 10px;
  }
  #siteContent {
      margin-top: 104px;
      /* margin-top: 56px; */
  }
  .storysection {
    padding-top:40px;
    /* padding-top: 0; */
  }
  .page-heading {
    height: 90px !important;
  }
  .storysection {
    margin-top: 0;
  }
  .stats-box h2 {
    font-size: 42px !important;
    color: #ffffff;
  }
}

@media (min-width: 320px) and (max-width: 414px) {
  .largescreen {
    display: none;
  }
  .banner-section .carousel-inner .carousel-item img {
    height: auto;
  }
  .socialicon ul li {
      padding: 0 5px;
  }
  .sitebrand img {
    width: 150px;
  } 
  .btntreatmentweoffer, .btntreatmentweoffer:hover {
    font-size: 16px;
  }
}

@media (max-width: 735px) {
  .largescreen {
    display: none;
  }
}

@media (min-width: 736px) {
  .smallscreen {
    display: none;
    position: relative;
    top: -14px;
    right: 40px;
  }
}

iframe {
  height: 350px;
}

/* About Page Start's Here*/

.page-heading {
  background-size: cover;
  width: 100%;
  height: 225px;
  background-position: top center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.page-heading h2 {
  font-family: 'Anton', sans-serif;
  color: #ffffff;
  margin: 0;
}

.about-content h1, .refund-policy h1, .contactus-content h1 {
  font-family: 'Gabriela';
}

.mission-vision {
  background-color: lightsalmon;
}

.contentBox {
  padding: 2rem;
  background-color: #ffffff;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentBox .mv-box h5 {
  font-family: 'Anton', sans-serif;
}

.contentBox.active {
  background-color: #88b44e;
  color: #fff;
  letter-spacing: 1px;
  text-transform: uppercase;
}

/* About Page End's Here*/

/* Refund Policy Page */

.refund-policy ol {
  padding-left: 15px;
}

.refundimg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.refundimg img {
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
}

/* Refund Policy Page End's Here */

/* Blog Post Start's Here */

.importanttags ul {
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  flex-wrap: wrap;
}

.importanttags ul li {
  background-color: #88b44e;
  padding: 0.35rem 1rem;
  margin-bottom: 10px;
  border-radius: 2px;
  color: #fff;
}

.next-previous, .recentpost {
  display: flex;
  justify-content: space-between;
}

.next-previous .btnprevious, .next-previous .btnnext {
  width: 125px;
}

.recentpost {
  margin-bottom: 1rem;
}

.recentpost .recentpostimg {
  width: 35%;
}

.recentpost .recentpostcontent {
  width: 65%;
  margin-left: 5px;
}

.recentpost .recentpostcontent h5, .recentpost .recentpostcontent h5 a {
  font-size: 14px;
  margin-bottom: 1px;
  color: #222;
}

.recentpost .recentpost span {
  font-size: 12px;
}

/* Blog Post End's Here */

/* Contact Page Start's Here */

.contact {
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 2rem;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.contact:hover {
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3);
}

.contact .contact-box h5 {
  font-size: 24px;
  font-weight: 600;
}

/* Contact Page End's Here */

/* Treatment Page Start's Here */

.treatment-heading {
  margin: 0;
  color: #ffffff;
  font-size: 20px;
  padding: 10px 16px;
}
.bg-m {
  background-color: #E31E25;
}
.bg-ar {
  background-color: #EF7F1B;
}
.bg-sp {
  background-color: #008DD2;
}
.bg-wi {
  background-color: #009746;
}
.bg-f {
  background-color: #E4097F;
}
.treatment-content .nav-pills .nav-link {
  background-color: #f1f1f1;
  color: #222;
  border-radius: 0;
  margin-bottom: 2px;
  transition: all 0.25s ease-out 0s;
  cursor: pointer;
}

.treatment-content .nav-pills .nav-link.active {
  background-color: #88b44e;
  color: #fff;
  border-radius: 0;
  position: relative;
}

.treatment-content .nav-pills .nav-link.active::after {
  content: '';
  position: absolute;
  right: -10px;
  top: 8px;
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 12px solid #88b44e;
}

@media (max-width: 660px) {
  .treatment-content .nav-pills .nav-link.active::after {
    content: '';
    position: absolute;
    right: -10px;
    top: 8px;
    width: 0;
    height: 0;
    border-top: 0 solid transparent;
    border-bottom: 0 solid transparent;
    border-left: 0 solid #88b44e;
  }
}

.treatmentContent h4 {
  font-size: 24px;
  font-weight: 700;
}

.treatmentContent h5 {
  font-size: 18px;
  font-weight: 700;
}

/* Treatment Page Start's Here */

/* Stats */
.stats-section {
  background-size: cover;
  background-position: center;
  height: 450px;
  background-attachment: fixed;
  justify-content: center;
  align-items: center;
  display: flex;
}
.stats-box h2 {
  font-size: 72px;
  color: #ffffff;
}
.stats-box p {
  color: #ffffff;
  font-size: 18px;
}
/* Stats */


.loader,
.loader:before,
.loader:after {
  background: #ffffff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: green;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
